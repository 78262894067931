/*============================================================================*\
   Global SCSS configuration
\*============================================================================*/
/*============================================================================*\
   Assets
\*============================================================================*/
/*============================================================================*\
   Typography
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/*============================================================================*\
   Layers
\*============================================================================*/
/*============================================================================*\
   Main entry point
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Enable of disable the helper classes */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/*============================================================================*\
   Function helper
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @return {string}                  A media query expression
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/*============================================================================*\
   Color helpers
\*============================================================================*/
/*============================================================================*\
   SVG Color helpers
\*============================================================================*/
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/**
 * Definition of the space rules
 */
/*============================================================================*\
   Helper function
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Space gutter
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-x2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-x4--xxl` class.
 *
 */
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/*============================================================================*\
   Helper mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 * @source http://cloud.meta.fr/8fdbea50b775
 */
/*============================================================================*\
   Helper classes
\*============================================================================*/
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/**
 * A map to define all font-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * The `fz($font-size, $unit)` and the `lh($font-size)` functions below can be
 * used to get easily one of the two values.
 *
 * @type {Map}
 */
/*============================================================================*\
   Font-sizes helper function
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/**
 * A function helper to get the computed line-height of the given font-size
 * @param  {string} $font-size The name of the font-size
 * @return {string}            The corresponding line-height
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 * @param  {string} $font-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/*============================================================================*\
   Fontaces declarations
\*============================================================================*/
/*============================================================================*\
   Responsive type mixin
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-font  The minimum font-size
 * @param  {integer} $max-font  The maximum font-size
 * @return {void}
 */
/*============================================================================*\
   Antialiasing mixin
\*============================================================================*/
/**
 * Antialiasing for better font rendering
 */
/*============================================================================*\
   Type font-size helpers
\*============================================================================*/
/*============================================================================*\
   Type alignement helpers
\*============================================================================*/
/*============================================================================*\
   Type font families helpers
\*============================================================================*/
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/*============================================================================*\
   Type spacing
\*============================================================================*/
/*============================================================================*\
   Type transform
\*============================================================================*/
/*============================================================================*\
   Type font defaults
\*============================================================================*/
/*============================================================================*\
   UI spacings
\*============================================================================*/
/*==========================================================================*\
   Lazyload block
\*==========================================================================*/
.lazyload {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: #eee;
}

/*==========================================================================*\
   Loader element
\*==========================================================================*/
.lazyload__loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Loader transition modifiers
\*==========================================================================*/
.lazyload__loader--enter,
.lazyload__loader--leave-to {
  opacity: 0;
  transform: scale(0);
}
.lazyload__loader--enter-active,
.lazyload__loader--leave-active {
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*==========================================================================*\
   Figure element
\*==========================================================================*/
.lazyload__fig {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Transition modifiers
\*==========================================================================*/
.lazyload__fig--enter,
.lazyload__fig--leave-to {
  opacity: 0;
}
.lazyload__fig--enter-active,
.lazyload__fig--leave-active {
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*==========================================================================*\
   Image element
\*==========================================================================*/
.lazyload__img {
  position: absolute;
  width: 1px;
  height: 1px;
  white-space: nowrap;
  pointer-events: none;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  pointer-events: none;
  opacity: 0;
}
