/*============================================================================*\
   Global SCSS configuration
\*============================================================================*/
/*============================================================================*\
   Assets
\*============================================================================*/
/*============================================================================*\
   Typography
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/*============================================================================*\
   Layers
\*============================================================================*/
/*============================================================================*\
   Main entry point
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Enable of disable the helper classes */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/*============================================================================*\
   Function helper
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @return {string}                  A media query expression
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/*============================================================================*\
   Color helpers
\*============================================================================*/
/*============================================================================*\
   SVG Color helpers
\*============================================================================*/
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/**
 * Definition of the space rules
 */
/*============================================================================*\
   Helper function
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Space gutter
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-x2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-x4--xxl` class.
 *
 */
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/*============================================================================*\
   Helper mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 * @source http://cloud.meta.fr/8fdbea50b775
 */
/*============================================================================*\
   Helper classes
\*============================================================================*/
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/**
 * A map to define all font-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * The `fz($font-size, $unit)` and the `lh($font-size)` functions below can be
 * used to get easily one of the two values.
 *
 * @type {Map}
 */
/*============================================================================*\
   Font-sizes helper function
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/**
 * A function helper to get the computed line-height of the given font-size
 * @param  {string} $font-size The name of the font-size
 * @return {string}            The corresponding line-height
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 * @param  {string} $font-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/*============================================================================*\
   Fontaces declarations
\*============================================================================*/
/*============================================================================*\
   Responsive type mixin
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-font  The minimum font-size
 * @param  {integer} $max-font  The maximum font-size
 * @return {void}
 */
/*============================================================================*\
   Antialiasing mixin
\*============================================================================*/
/**
 * Antialiasing for better font rendering
 */
/*============================================================================*\
   Type font-size helpers
\*============================================================================*/
/*============================================================================*\
   Type alignement helpers
\*============================================================================*/
/*============================================================================*\
   Type font families helpers
\*============================================================================*/
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/*============================================================================*\
   Type spacing
\*============================================================================*/
/*============================================================================*\
   Type transform
\*============================================================================*/
/*============================================================================*\
   Type font defaults
\*============================================================================*/
/*============================================================================*\
   UI spacings
\*============================================================================*/
.ovs {
  overflow: scroll;
}
.tabs:after {
  content: "";
  display: table;
  clear: both;
}
.tabs__tab {
  position: relative;
  float: left;
  display: block;
  width: 50%;
  opacity: 0.6;
  transition: 0.4s all cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tabs__tab:first-of-type:after {
    content: '';
    display: block;
    width: 1px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    background: #000;
    opacity: 0.6;
    transform: translateY(-50%);
}
.tabs__tab.active, .tabs__tab:hover {
    opacity: 1;
}
.tabs-container {
  position: relative;
}
.condition {
  border-top: 1px solid #eee;
}
.page {
  z-index: 300;
  width: 100%;
  height: 100%;
  padding-top: 42vh;
}
@media (min-width: 48em) {
.page {
      padding-top: 55%;
}
}
.content {
  z-index: 201;
  color: #fff;
  text-align: center;
}
.page-enter .content,
  .page-leave-to .content {
    opacity: 0;
}
.page-enter-active .content,
  .page-leave-active .content {
    transition: opacity 1.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@media (min-width: 64em) {
.content {
      text-align: left;
      max-width: 40em;
}
.content p {
        max-width: 27em;
}
}
@media all and (min-width: 0em) {
.content {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
}
}
@media all and (min-width: 30em) {
.content {
      padding-left: 2rem;
      padding-right: 2rem;
}
}
@media all and (min-width: 48em) {
.content {
      padding-left: 3rem;
      padding-right: 3rem;
}
}
@media all and (min-width: 80em) {
.content {
      padding-left: 5rem;
      padding-right: 5rem;
}
}
@media all and (min-width: 120em) {
.content {
      padding-left: 6.5rem;
      padding-right: 6.5rem;
}
}
.aside {
  z-index: 201;
  display: flex;
  flex-basis: 100%;
  max-width: 26em;
  margin: 3rem auto 0;
  padding-bottom: 2rem;
  color: #222;
  background-color: #fff;
}
.page-enter .aside,
  .page-leave-to .aside {
    transform: translate(0, 50vh);
}
.page-enter-active .aside,
  .page-leave-active .aside {
    transition: 1.2s transform cubic-bezier(0.19, 1, 0.22, 1);
}
@media (min-width: 48em) {
.aside {
      margin: 5rem auto;
      padding-bottom: 0;
}
}
@media (min-width: 64em) {
.aside {
      position: fixed;
      top: 0;
      left: 100%;
      width: 33.333%;
      max-width: 600px;
      height: 100%;
      margin: 0;
      overflow: scroll;
      transform: translate(-100%, 0);
}
.page-enter .aside,
      .page-leave-to .aside {
        transform: translate(0);
}
}
@media (min-height: 40em) {
.aside {
      align-items: center;
}
}
.aside__header img {
  display: block;
  width: 100%;
  max-width: 250px;
  height: auto;
  margin: 0 auto;
}
.aside__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-height: 40em) {
.aside__inner {
      height: 100%;
}
}
ol {
  text-align: left;
  margin: 0;
  padding-left: 0;
  list-style: none;
  counter-reset: li;
}
ol li {
    display: block;
    padding: 1rem 0;
    font-size: 16px;
    border-top: 1px solid #eee;
    counter-increment: li;
}
ol li::before {
      content: counter(li) ". ";
}
ol li:last-child {
      border-bottom: 1px solid #eee;
}
.background__bg {
  opacity: 1;
  mix-blend-mode: initial;
}
.aside__footer {
  position: relative;
  margin: 0;
  list-style: none;
}
.aside__footer li {
    position: relative;
}
.aside__footer li:first-child {
      border-bottom: 1px solid #dddddd;
}
.aside__footer li a {
      color: #666;
}
.aside__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aside__link path:last-child {
    transition: 0.6s transform cubic-bezier(0.215, 0.61, 0.355, 1);
    transform-origin: right top;
}
.aside__link span {
    transition: 0.6s opacity cubic-bezier(0.215, 0.61, 0.355, 1);
}
.aside__link:hover path:last-child {
    transform: scaleX(0.7);
}
.aside__link:hover span {
    opacity: 0.7;
}
@media (min-width: 64em) {
.app {
    overflow: hidden;
}
.page {
    height: 60vh;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 0;
}
}

/*==========================================================================*\
   Word element
\*==========================================================================*/
.box__word {
  display: inline-block;
  white-space: nowrap;
}

/*==========================================================================*\
   Letter element
\*==========================================================================*/
.box__letter {
  display: inline-block;
  opacity: 0.99;
}
.back-btn {
  display: inline-block;
  padding-left: 0;
  transform: rotate(180deg);
}
